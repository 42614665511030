// Used for manual interaction with ujs
window.Rails = require("@rails/ujs");
Rails.start();

require("turbolinks").start();

import './stylesheets.css';
import './common.scss';
import './tooltips.css';

require("jquery");

const loadScanner = function() {
  window.addEventListener('turbolinks:load', () => {
    const input = document.getElementById("qr-code-input")
    input.focus()

    input.oninput = function () {
      Rails.fire(this.form, "submit")
    };

    const clearQrButton = document.getElementById("clear-qr-button");

    clearQrButton.onclick = function (e) {
      e.preventDefault();
      const resultBox = document.getElementById("result-box")
      resultBox.innerHTML = '';
      input.value = '';
      input.focus();
    }
  });
}

window.loadScanner = loadScanner;